<template>
  <div class="allProList">
    <TopNavBar :clear-search-name="clearSearchName" @prtName="handlePrtName" />
    <div class="content">
      <div class="right">
        <van-sidebar v-model="active" @change="onChangeSidebar">
          <van-sidebar-item v-for="(item,index) in categoryList" :key="index" :title="item.typeName" />
        </van-sidebar>
      </div>
      <div class="list-container">
        <div class="list">
          <div v-if="isLoading" class="loading no-item"><van-loading color="#0094ff" /></div>
          <template v-if="loading && proList.length !== 0">
            <div v-for="(item, index) of proList" :key="index" class="item" @click="goProDetail(item)">
              <van-image width="69px" height="69px" style="margin:0 0 5.5px 0" fit="cover" :src="imgList[index]" alt="" />
              <span class="text">{{ item.prtName }}</span>
            </div>
          </template>
          <div v-if="loading && proList.length == 0" :key="loading" class="no-item">暂无产品</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopNavBar from './components/TopNavBar.vue'
import { getAppProTypeList, getProList, getFileList } from '@/api'
export default {
  name: 'AllProList',
  components: {
    TopNavBar
  },

  data() {
    return {
      active: '',
      categoryList: [],
      proList: [],
      prtType: '',
      prtName: '',
      imgList: [],
      isLoading: false,
      loading: false,
      clearSearchName: ''
    }
  },
  created() {
    this.getAppProTypeList()
  },
  methods: {
    goProDetail(item) {
      this.$router.push({ name: 'ProductDetails', query: { uuid: item.uuid,title:item.title ,shopUuid:this.$store.state.shopUuid}})
    },

    getAppProTypeList() {
      const params = {
        shopUuid: this.$store.state.shopUuid
      }
      getAppProTypeList(params).then(res => {
        if (res.state == 100) {
          this.categoryList = [...res.items]
          this.prtType = (this.categoryList[0] || {}).typeName
          this.getProList()
        }
      })
    },

    getProList() {
      const params = {
        isOn: 0,
        page: 0,
        pageSize: 20,
        shopUuid: this.$store.state.shopUuid,
        prtType: this.prtType || '',
        prtName: this.prtName || ''
      }
      this.isLoading = true
      this.loading = false
      getProList(params).then(res => {
        if (res.state == 100) {
          this.proList = res.items
          this.proList.forEach((item, index) => {
            this.getFileList(item.uuid, index)
          })
          this.loading = true
        }
        this.isLoading = false
      })
    },

    onChangeSidebar(e) {
      this.prtType = this.categoryList[e].typeName
      this.clearSearchName = this.prtType
      this.getProList()
    },

    handlePrtName(e) {
      this.prtName = e
      this.getProList()
    },

    getFileList(uuid, index) {
      const params = {
        page: 0,
        pageSize: 0,
        typeName: 'prtPicture',
        parentUuid: uuid
      }
      getFileList(params).then(res => {
        if (res.state == 100) {
          this.$set(this.imgList, index, res.items[0].filePath)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.allProList {
  width: 100%;
  height: 100vh;
  overflow: hidden;

  .content {
    display: flex;
    .list-container{
      width: 100%;
      overflow: auto;
      background: #fff;
      .list {
      width: 100%;
      overflow-y: auto;
      max-height: calc(100vh - 60px);

      display: flex;
      flex-wrap: wrap;
      padding: 15px 0 15px 14px;
      .item{
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        box-sizing: border-box;
        width: 84px;
        // height: 98px;
        padding: 0 15px 14.5px 0;
        .text{
          width: 100%;
          height: 13px;
          overflow: hidden;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #646464;
          overflow: hidden;
          /*line-height: 14px;*/
          word-break: break-all;
        }

      }
      // .item {
      //   height: 87px;
      //   justify-content: center;
      //   align-items: center;
      //   margin-right: 15px;
      //   margin-bottom: 15px;

      //   & > img {
      //     width: 69px;
      //     height: 69px;
      //     margin-bottom: 5px;
      //   }

      //   & > span {
      //     display: inline-block;
      //     width: 69px;
      //     font-size: 13px;
      //     font-family: PingFang SC;
      //     font-weight: 500;
      //     color: #646464;
      //     overflow: hidden;
      //     white-space: nowrap;
      //     text-overflow: ellipsis;
      //   }
      // }

      .no-item {
        width: 100%;
        padding-top: 50px;
        text-align: center;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 700;
        color: #646464;
      }
    }
    }

  }

  .right {
    width: 109px;
    height: 100%;
  }

  /deep/.van-sidebar {
    width: 109px;
    height: calc(100vh - 60px);
    background-color: #e5e5e5;

    .van-sidebar-item--select::before {
      background-color: #4f5aff;
      width: 3px;
      height: 100%;
    }

    .van-sidebar-item {
      background-color: #e5e5e5;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #646464;
    }
    .van-sidebar-item--select,
    .van-sidebar-item--select:active {
      background-color: #f5f5f5;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #646464;
    }
  }
}
</style>
