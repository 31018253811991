<template>
	<van-nav-bar left-arrow>
		<template #left>
			<img width="28px" height="28px" src="~@/assets/img/nav/back.png" alt="" @click="goBack(1)">
		</template>
		<template #title>
			<div class="search-top" style="border-radius:400px">
				<img class="left" src="@/assets/img/home/searchIcon.png" @click="searchClick">
				<input v-model="searchName" placeholder="请输入想要搜索的产品" class="search-input" @keyup.enter="searchClick">
				<!-- <img @click="handleVoice" class="right" src="@/assets/img/home/voice.png" /> -->
			</div>
		</template>
		<template #right>
			<img width="27.5px" height="28px" src="~@/assets/img/backTop/cate-home.png" alt="" @click="goBack(2)">
		</template>
	</van-nav-bar>
</template>

<script>
	import {
		Toast
	} from 'vant'
	export default {
		name: 'TopNavBar',
		props: {
			clearSearchName: {
				type: String,
				default: ''
			}
		},
		data() {
			return {
				searchName: ''
			}
		},
		watch: {
			clearSearchName(newValue, oldValue) {
				this.searchName = ''
				this.searchClick()
			}
		},
		methods: {
			goBack(type) {
				switch (type) {
					case 1:
						this.$router.go(-1)
						break
					case 2:
						this.$router.push({
							name: 'Home',
							query: {
								shopUuid: this.$store.state.shopUuid
							}
						})
						break
				}
			},

			searchClick() {
				this.$emit('prtName', this.searchName)
			},
			handleVoice() {
				Toast('网页暂不支持')
			}
		}
	}
</script>

<style lang="less" scoped>
	.search-top {
		width: 220px;
		height: 33px;
		background: #f4f4f4;
		border-radius: 100px;
		display: flex;
		align-items: center;
		position: relative;

		.left {
			margin: 0 8px 0 13px;
		}

		.right {
			position: absolute;
			right: 12px;
		}

		&>.search-input {
			width: 150px;
			white-space: nowrap;
			font-size: 13px;
			font-family: PingFang SC;
			font-weight: 500;
			color: #787878;
			border: none;
			outline: none;
			background: transparent;

			&::placeholder {
				color: #787878;
			}
		}

		&>img {
			width: 17px;
			height: 17px;
		}
	}

	.home-logo {
		width: 112px;
		height: 29px;
	}

	.home-left,
	.home-right {
		width: 29px;
		height: 29px;
	}

	[class*="van-hairline"]::after {
		border: none;
	}
</style>
